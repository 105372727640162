<template>
    <BT-Blade-Item
        bladeName="supplier"
        :bladesData="bladesData"
        :canEdit="false"
        :canSave="false"
        :loadingMsg="loadingMsg"
        navigation="suppliers"
        :onCanDelete="item => item != null && item.seller != null && item.seller.isManagedByAnotherAccount"
        :otherUsedHeight="48"
        :refreshToggle="refreshToggle"
        :scroll="false"
        title="Supplier">
        <template v-slot="{ data, item }">
            <v-container v-if="data.isNew">
                <v-row>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.companyName"
                            label="Company Name"
                            isEditing
                            :rules="requiredRules()" />
                            
                        <BT-Field-String
                            v-model="item.email"
                            label="Email Address"
                            isEditing
                            :rules="requiredRules()" />

                        <BT-Field-String
                            v-model="item.phoneNumber"
                            label="Phone Number"
                            isEditing />

                    </v-col>

                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            label="Address Line One"
                            v-model="item.addressLineOne"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Street Number"
                            v-model="item.streetNumber"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Street Name"
                            v-model="item.streetName"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Suburb"
                            v-model="item.suburb"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="State"
                            v-model="item.state"
                            :isEditing="item.companyName != null && item.email != null" />
                        <BT-Field-String
                            label="Postcode"
                            v-model="item.postcode"
                            :isEditing="item.companyName != null && item.email != null" />
                    </v-col>
                </v-row>
                <v-divider />
                <v-row>
                    <v-col cols="6">
                        <v-list-item>
                            <v-list-item-content>
                                <v-btn class="primary" :disabled="item.suburb == null && item.state == null && item.postcode == null" @click="validate(item)">
                                    Find Address Coordinates
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>

                        <BT-Field-String
                            label="Latitude"
                            v-model="item.lat"
                            isEditing />

                        <BT-Field-String
                            label="Longitude"
                            v-model="item.lng"
                            isEditing />
                    </v-col>
                    <v-col cols="6">
                        <GmapMap
                            v-if="item.lat != null && item.lng != null"
                            ref="locMap"
                            :center="centerPosition"
                            :zoom="7"
                            style="width: 100%; height: 250px;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">
                            <gmap-marker v-if="position != null" :position="position" />
                        </GmapMap>
                    </v-col>
                </v-row>
                <v-divider />
                <v-btn v-if="item.lat != null && item.lng != null" class="primary" @click="create(item)">
                    Create
                </v-btn>
                <BT-Snack v-model="msg" />
            </v-container>
            <v-container v-else-if="item != null && item.seller != null">
                <BT-Entity
                    v-if="item.seller.isManagedByAnotherAccount"
                    ignoreID
                    navigation="managed-company-details"
                    :proxyID="item.seller.id"
                    single>
                    <template v-slot="{ item, data, save }">
                        <v-row>
                            <v-col cols="12" sm="6" class="d-flex flex-column justify-center align-center">
                                <BT-Image-Edit
                                    canEdit
                                    :height="120"
                                    :id="item.id"
                                    justify-center align-center
                                    navigation="customer-image"
                                    placeholder="mdi-account-box"
                                    :src="companyLogoURL(item.id) + '?' + cacheData"
                                    @uploaded="cacheData = new Date().getTime()"
                                    :width="120" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <BT-Field-String
                                    v-model="item.companyName"
                                    label="Company Name"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />
                                    
                                <BT-Field-String
                                    v-model="item.primaryEmail"
                                    label="Primary Email Address"
                                    :isEditing="data.isEditing || data.isNew"
                                    :rules="requiredRules()" />

                                <BT-Field-String
                                    v-model="item.phoneNumber"
                                    label="Phone Number"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-String
                                    v-model="item.website"
                                    label="Website"
                                    :isEditing="data.isEditing || data.isNew" />

                                <BT-Field-Select
                                    label="Default Timezone"
                                    v-model="item.defaultTimeZone"
                                    :items="timeZones"
                                    :isEditing="data.isEditing"
                                    :rules="requiredRules()" />

                            </v-col>
                        </v-row>
                        <v-slide-y-transition hide-on-leave>
                            <v-row v-if="data.isChanged" class="mt-0">
                                <v-btn v-if="data.isChanged" @click="save" block class="primary mt-5">
                                    <v-icon left>mdi-content-save</v-icon>Save
                                </v-btn>
                            </v-row>
                        </v-slide-y-transition>
                    </template>
                </BT-Entity>
                <v-row v-else>
                    <v-col cols="12" sm="6" class="text-center">
                        <v-img
                            class="mx-auto"
                            height="150"
                            :src="companyLogoURL(item.seller.id)"
                            width="150">
                            <template v-slot:placeholder>
                                <v-icon size="150" color="primary">mdi-account-box</v-icon>
                            </template>
                        </v-img>
                    </v-col>
                    <v-col cols="6">
                        <BT-Field-String
                            v-model="item.seller.companyName"
                            label="Company Name"
                            :rules="requiredRules()" />
                    </v-col>
                </v-row>

                <v-divider class="my-4" />

                <v-row v-if="item != null">
                    <v-col v-if="item.seller.isManagedByAnotherAccount" cols="1">
                        <v-list width="56">
                            <BT-Menu
                                icon="mdi-map-marker-multiple"
                                :item="item"
                                right
                                title="Locations">
                                <template>
                                    <BT-Blade-Items
                                        addBladeName="location"
                                        actualHeight="200px"
                                        canAdd
                                        hideBackButton
                                        hideHeader
                                        navigation="locations"
                                        :proxyID="item.seller.id"
                                        showList
                                        title="Locations">
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item | toFamiliarLocationLine }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Items>
                                </template>
                            </BT-Menu>
                            <BT-Menu
                                icon="mdi-cube-outline"
                                :item="item"
                                right
                                title="Products">
                                <template>
                                    <BT-Blade-Items
                                        addBladeName="product"
                                        actualHeight="200px"
                                        canAdd
                                        hideBackButton
                                        hideHeader
                                        navigation="products"
                                        :proxyID="item.seller.id"
                                        showList
                                        title="Products">
                                        <template v-slot:listItem="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.productName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.abbreviation }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </BT-Blade-Items>
                                </template>
                            </BT-Menu>
                        </v-list>
                    </v-col>
                    <v-col :cols="item.seller.isManagedByAnotherAccount ? 11 : 12">
                        <v-row class="mt-2" dense>
                            <v-spacer />
                            <BT-Sidebar-External-Links
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.id"
                                label="Integrate"
                                navigation="suppliers"
                                small
                                syncNavigation="supplier-syncing" />

                            <BT-Sidebar-Live-Links
                                v-if="item.seller.isManagedByAnotherAccount"
                                buttonClass="ma-1 primary"
                                inline
                                :itemID="item.seller.id"
                                label="Go Live"
                                rightIcon="mdi-account-multiple-check"
                                small
                                sidebarLabel="Links" />
                            <div v-else class="mx-2 px-2 subtitle-1">
                                <v-icon left>mdi-account</v-icon>Live
                            </div>
                        </v-row>
                        
                        <v-row dense>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    actualHeight="325px"
                                    addBladeName="purchase-order"
                                    canAdd
                                    canSearchLocal
                                    :headers="[
                                        { text: 'CO#', value: 'customerOrderNumber', searchable: true },
                                        { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', subtitle: '1', prefix: 'Due: ' },
                                        { text: 'Status', value: 'status', display: true, subtitle: 2 },
                                        { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency', subtitle: 3 }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="6"
                                    :itemProperties="['ID','CustomerOrderNumber','DueDate','IsInactive','IsConfirmed','AmountTotal']"
                                    navigation="purchase-orders"
                                    :params="{ supplierIDs: item.seller.id }"
                                    showTable
                                    title="Orders">
                                    <template #toolbar-right>
                                        <v-btn class="primary" small text :to="{ name: 'purchase-orders' }">View All</v-btn>
                                    </template>
                                    <template #status="{ item }">
                                        <span v-if="item.isInactive">Deleted</span>
                                        <span v-else-if="item.isConfirmed == null">Awaiting Response</span>
                                        <span v-else-if="item.isConfirmed == true">Confirmed</span>
                                        <span v-else>Rejected</span>
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5" />
                        <v-row dense>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    actualHeight="325px"
                                    addBladeName="supplier-invoice"
                                    canSearchLocal
                                    :headers="[
                                        { text: 'INV#', value: 'invoiceNumber', searchable: true },
                                        { text: 'Due Date', value: 'dueOn', textFilter: 'toDayMonth', subtitle: '1', prefix: 'Due: ' },
                                        { text: 'Status', value: 'status', display: true, subtitle: 2 },
                                        { text: 'Total Value', value: 'amountTotal', textFilter: 'toCurrency', subtitle: 3 }]"
                                    hideActions
                                    hideBackButton
                                    hideHeader
                                    :itemsPerPage="6"
                                    :itemProperties="['ID','DueOn','InvoiceNumber','IsPaid','AmountPaid','AmountTotal']"
                                    navigation="supplier-invoices"
                                    :params="{ supplierIDs: item.seller.id }"
                                    showTable
                                    title="Invoices">
                                    <template #toolbar-right>
                                        <v-btn class="primary" small text :to="{ name: 'supplier-invoices' }">View All</v-btn>
                                    </template>
                                    <template v-slot:status="{ item }">
                                        <span v-if="item.isPaid">Paid</span>
                                        <span v-else-if="item.amountPaid > 0">Partially Paid</span>
                                        <span v-else>Unpaid</span>
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Supplier-Blade',
    components: {
        // BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        // BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        // BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTSidebarLiveLinks: () => import('~components/BT-Sidebar-Live-Links.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        // BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        // BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    props: {
        bladesData: null
    },
    data: function() {
        return {
            cacheData: new Date().getTime(),
            geocoder: null,
            loadingMsg: null,
            msg: null,
            panelV: null,
            position: null,
            refreshToggle: false,
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    computed: {
        //google: getGoogleMapsAPI,
        centerPosition() {
            return this.position != null ? this.position : { lat: -38, lng: 144 };
        },
        timeZones() {
            return moment.tz.names();
        }
    },
    methods: {
        repositionMarker(location) {
            if (location.lat != null && location.lng != null) {
                this.position = {
                    lat: location.lat,
                    lng: location.lng
                }
            }
            else {
                this.position = null;
            }
        },
        async create(item) {
            this.loadingMsg = `Creating ${item.companyName}`;

             var data = {
                subscriptionCode: 'SUPPFREE',
                companies: [item]
            };

            try {
                await this.$BlitzIt.api.post('new-suppliers', data, null, '/PostSuppliers');

                this.msg = 'Done';
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async validate(item) {
            try {
                this.loadingMsg = 'Validating Address';
            
                var add = getLocationLine(item, true);
                
                await this.$gmapApiPromiseLazy();
                var google = getGoogleMapsAPI();

                if (this.geocoder == null) {
                    //await googleMapsApiInitializer({ key: process.env.VUE_APP_GOOGLE_KEY }, false);
                    this.geocoder = new google.maps.Geocoder();
                }

                var results = await this.geocoder.geocode({ address: add });

                if (results.results.length > 1) {
                    this.msg = 'Multiple Options Found';
                }
                else if (results.results.length == 1) {
                    item.lat = results.results[0].geometry.location.lat();
                    item.lng = results.results[0].geometry.location.lng();
                    this.repositionMarker(item);
                }
                else {
                    this.msg = 'Address Not Found';
                }
            }
            catch (err) {
                this.msg = 'Failed';
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>